import { Button } from "@mui/material";
import useWeb3 from "../../hooks/useWeb3";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  {
    label: "Profile",
    linkTo: "/profile",
  },
  {
    label: "Settings",
    linkTo: "/settings",
  },
];

// ----------------------------------------------------------------------

export default function ConnectWEB3() {
  const { isConnected } = useWeb3();

  const handleConnect = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!isConnected && (
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleConnect}
        >
          Bağlan
        </Button>
      )}
    </>
  );
}
