import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isEtherium: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isConnected, account, isEtherium } = action.payload;
    return {
      ...state,
      isConnected,
      isInitialized: true,
      account,
      isEtherium,
    };
  },
  CONNECT: (state, action) => {
    const { account } = action.payload;

    return {
      ...state,
      isConnected: true,
      account,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const WEB3Context = createContext({
  ...initialState,
  connect: () => Promise.resolve(),
  disconnect: () => Promise.resolve(),
});

WEB3Provider.propTypes = {
  children: PropTypes.node,
};

function WEB3Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [provider, setProvider] = useState(undefined);

  useEffect(() => {
    const initialize = async () => {
      try {
        const provider = await detectEthereumProvider();
        if (provider !== window.ethereum) {
          return dispatch({
            type: "INITIALIZE",
            payload: {
              isConnected: false,
              account: null,
              isEtherium: false,
            },
          });
        }

        setProvider(new ethers.providers.Web3Provider(window.ethereum));
        window.ethereum.on("chainChanged", () => window.location.reload());

        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        const account = accounts.length !== 0 ? accounts[0] : null;

        window.ethereum.on("accountsChanged", (accounts) =>
          dispatch({ type: "CONNECT", payload: { account: accounts[0] } })
        );

        dispatch({
          type: "INITIALIZE",
          payload: {
            isConnected: account ? true : false,
            account,
            isEtherium: true,
          },
        });
      } catch (err) {
        console.error(err);
      }
    };

    initialize();
  }, []);

  return (
    <WEB3Context.Provider
      value={{
        ...state,
        provider,
      }}
    >
      {children}
    </WEB3Context.Provider>
  );
}

export { WEB3Context, WEB3Provider };
