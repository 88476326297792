// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// hooks
import useAuth from "./hooks/useWeb3";
// components
import LoadingScreen from "./components/LoadingScreen";
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>{isInitialized ? <Router /> : <LoadingScreen />}</ThemeConfig>
  );
}
